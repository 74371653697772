import React from "react"
import { Link, useStaticQuery } from "gatsby"
import GalleryCard from "../components/cards/gallerycard"

import Layout from "../components/layout"

const Metro = () => {
  const data = useStaticQuery(
    graphql`
      {
        allPrismicGallery(filter: { uid: { eq: "metro" } }) {
          edges {
            node {
              data {
                body {
                  ... on PrismicGalleryBodyImageGallery {
                    id
                    items {
                      gallery_image {
                        fluid(maxWidth: 1000, maxHeight: 800) {
                          ...GatsbyPrismicImageFluid
                        }
                      }
                      size {
                        text
                      }
                      medium
                      price
                      year
                      image_name {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  //console.log(data.allPrismicTestpage.edges[0].node.data.body[0].items)

  return (
    <Layout>
      <div className="max-w-2xl mx-auto">
        <p className="px-4">
          Metro this is the one of the strange feeling coming up from my early
          childhood and repeating quite often in my dreams! black hole of
          infinity tunnels without exit and white monotonic ceramics on wall.
          this tunnels are deep unknown dark spaces, with repeated voices and
          changing the trains. where is the exit and where the train goes I
          don't remember. I cannot interact with this darkness. It's something
          mystical, something dangerous, hypnotize magnetic and attracting, it
          touching borders beyond of my mind.. I feel huge gravity when I'm
          looking on it. But to see the exit I need tool much more stronger then
          eyes.. But witch tool , I dont remember !
        </p>
        {data.allPrismicGallery.edges[0].node.data.body[0].items.map(
          (item, i) => (
            <GalleryCard image={item} key={i} />
          )
        )}
      </div>
    </Layout>
  )
}

export default Metro
